<p-toast />
<div class="page-container manage-fields-container">
  <div class="header-container">
    <app-search-text (searchEvent)="handleSearch($event)" class="header" />
    <app-button label="Add" (clickEvent)="handleAddNew()" class="header" />
  </div>

  <p-table
    [columns]="cols"
    [value]="ManageFieldList"
    [paginator]="true"
    [rows]="10"
    dataKey="id"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
    [(selection)]="selectedRows"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 4rem">
          <p-tableHeaderCheckbox />
        </th>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton"><p-skeleton></p-skeleton></th>
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>
          <p-tableCheckbox [value]="rowData" />
        </td>
        <td>{{ rowData.code }}</td>
        <td>{{ rowData.label }}</td>
        <td>{{ formatFieldType(rowData.fieldType) }}</td>
        <td>{{ rowData.description }}</td>
        <td>{{ rowData?.fieldStage ?? "-" }}</td>
        <td>{{ rowData?.purpose }}</td>
        <td>{{ rowData.alternateFieldCodes }}</td>
        <td>{{ rowData.isMandatory ? "True" : "False" }}</td>
        <td>{{ rowData.isScanner ? "True" : "False" }}</td>
        <td>{{ rowData.isAudit ? "True" : "False" }}</td>
        <td>{{ rowData.licenseType ?? "-" }}</td>
        <td (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-overlayPanel #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div>
              <div
                (mouseover)="handleHover(2)"
                (click)="handleDelete(rowData)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="ManageFieldList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="8">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '45vw' }"
  >
    <ng-template pTemplate="header">
      <h3>{{ edit ? "Edit" : "Add" }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div class="grid-container">
          <app-input
            *ngIf="!edit"
            label="Code"
            [control]="formData.get('code')"
            [required]="true"
          />
          <app-input
            label="Label"
            [control]="formData.get('label')"
            [required]="true"
          />
          <app-dropdown
            *ngIf="!edit"
            [options]="typeList"
            [control]="formData.get('fieldType')"
            label="Type"
            [required]="true"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
          />
          <app-dropdown
            *ngIf="!edit"
            [options]="licenseType"
            [control]="formData.get('licenseType')"
            label="License Type"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
            [showClear]="true"
          />
          <app-dropdown
            *ngIf="!edit"
            [options]="fieldStage"
            [control]="formData.get('fieldStage')"
            label="Field Stage"
            placeholder="Select"
            optionLabel="label"
            optionValue="label"
            [required]="true"
          />

          <div
            *ngIf="!edit"
            style="display: flex; gap: 1.5rem; margin-bottom: 1rem"
          >
            <div>
              <label class="input-label">Mandatory</label>
              <p-checkbox
                formControlName="isMandatory"
                [binary]="true"
                inputId="binary"
              />
            </div>
            <div>
              <label class="input-label">Audit</label>
              <p-checkbox
                formControlName="isAudit"
                [binary]="true"
                inputId="binary"
              />
            </div>
            <div>
              <label class="input-label">Scanner</label>
              <p-checkbox
                formControlName="isScanner"
                [binary]="true"
                inputId="binary"
              />
            </div>
          </div>

          <app-dropdown
            *ngIf="!edit"
            [options]="fieldPurpose"
            [control]="formData.get('purpose')"
            label="Field Purpose"
            placeholder="Select"
            optionLabel="label"
            optionValue="label"
            [required]="true"
          />
        </div>
        <div style="margin-bottom: 1rem">
          <label class="input-label">Alternative Fields</label>
          <p-autoComplete
            formControlName="alternateFieldCodes"
            [suggestions]="autoComplete"
            (completeMethod)="autoCompleteFunc($event)"
            [multiple]="true"
            field="label"
            appendTo="body"
          ></p-autoComplete>
          <div
            class="error-message"
            *ngIf="
              formData.get('alternateFieldCodes')?.invalid &&
              formData.get('alternateFieldCodes')?.touched
            "
          >
            Alternative Fields is required.
          </div>
        </div>
        <div>
          <label class="input-label">
            Description
            <span class="required-label">*</span>
          </label>
          <textarea
            rows="2"
            cols="30"
            pInputTextarea
            formControlName="description"
            class="textarea"
          ></textarea>
          <div
            class="error-message"
            *ngIf="
              formData.get('description')?.invalid &&
              formData.get('description')?.touched
            "
          >
            Description is required.
          </div>
        </div>

        <!-- CONSTANT FORM AND TABLE -->
        <div *ngIf="formData.get('fieldType')?.value === 'Constant'">
          <label class="input-label">Values</label>
          <form class="constant-container" [formGroup]="constantValueForm">
            <app-dropdown
              placeholder="Zone"
              [control]="constantValueForm.get('zone')"
              [options]="zoneList"
              optionLabel="name"
            />
            <app-calendar [control]="constantValueForm.get('from_date')" />
            <app-input
              placeholder="Value"
              [control]="constantValueForm.get('values')"
            />
            <app-button
              *ngIf="!editConstant"
              label="Add"
              (clickEvent)="constantValueAdd()"
              style="margin-top: 0.3rem"
            />
            <app-button
              *ngIf="editConstant"
              label="Edit"
              (clickEvent)="constantValueEdit()"
              style="margin-top: 0.3rem"
            />
          </form>
          <p-table [columns]="ConstantCols" [value]="constantList">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">{{ col.header }}</th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-rowData
              let-columns="columns"
              let-i="rowIndex"
            >
              <tr>
                <td>{{ rowData?.zone?.name ?? "All Zone" }}</td>
                <td>{{ rowData.from_date | date : "yyyy-MM-dd" }}</td>
                <td>{{ rowData.values }}</td>
                <td (click)="op.toggle($event)">
                  <img src="assets/ActionMenu.svg" />
                  <p-overlayPanel #op>
                    <ng-template pTemplate="content">
                      <div
                        (mouseover)="handleHover(1)"
                        (click)="constantEdit(rowData, i)"
                        class="list-item"
                        [class.hover-bg-light-blue]="onHover === 1"
                        (mouseleave)="onHover = 0"
                      >
                        <i
                          class="pi pi-user-edit"
                          style="font-size: 1.3rem"
                        ></i>
                        <span style="font-size: 14px">Edit</span>
                      </div>
                      <div
                        (mouseover)="handleHover(2)"
                        (click)="constantDelete(i)"
                        class="list-item"
                        [class.hover-bg-light-red]="onHover === 2"
                        (mouseleave)="onHover = 0"
                      >
                        <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                        <span style="font-size: 14px">Delete</span>
                      </div>
                    </ng-template>
                  </p-overlayPanel>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No Data found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <!-- PICKLIST FORM AND TABLE -->
        <div *ngIf="formData.get('fieldType')?.value === 'DE_Picklist'">
          <label class="input-label">Values</label>
          <app-picklist
            (picklistData)="getPicklistValue($event)"
            [picklistValue]="picklistData"
          />
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        *ngIf="!edit"
        label="Save"
        [disabled]="formData.invalid"
        (clickEvent)="onSave()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
      <app-button
        *ngIf="edit"
        label="Update"
        [disabled]="formData.invalid"
        (clickEvent)="onUpdate()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="deleteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Field ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Delete"
        (clickEvent)="onDeleteField()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>
